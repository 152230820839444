export const DEFAULT_TOKEN_LIST = [
  {
    address: '0xF0939011a9bb95c3B791f0cb546377Ed2693a574',
    chainId: 1,
    name: 'Zero',
    symbol: 'ZERO',
    decimals: 18
  },
  {
    address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    chainId: 1,
    name: 'Wrapped Ether',
    symbol: 'WETH',
    decimals: 18
  },
  {
    address: '0xB6eD7644C69416d67B522e20bC294A9a9B405B31',
    chainId: 1,
    name: '0xBitcoin Token',
    symbol: '0xBTC',
    decimals: 8
  },
  {
    address: '0xfC1E690f61EFd961294b3e1Ce3313fBD8aa4f85d',
    chainId: 1,
    name: 'Aave Interest bearing DAI',
    symbol: 'aDAI',
    decimals: 18
  },
  {
    address: '0x737F98AC8cA59f2C68aD658E3C3d8C8963E40a4c',
    chainId: 1,
    name: 'Amon',
    symbol: 'AMN',
    decimals: 18
  },
  {
    address: '0xD46bA6D942050d489DBd938a2C909A5d5039A161',
    chainId: 1,
    name: 'Ampleforth',
    symbol: 'AMPL',
    decimals: 9
  },
  {
    address: '0xcD62b1C403fa761BAadFC74C525ce2B51780b184',
    chainId: 1,
    name: 'Aragon Network Juror',
    symbol: 'ANJ',
    decimals: 18
  },
  {
    address: '0x960b236A07cf122663c4303350609A66A7B288C0',
    chainId: 1,
    name: 'Aragon Network Token',
    symbol: 'ANT',
    decimals: 18
  },
  {
    address: '0x27054b13b1B798B345b591a4d22e6562d47eA75a',
    chainId: 1,
    name: 'AirSwap Token',
    symbol: 'AST',
    decimals: 4
  },
  {
    address: '0xBA11D00c5f74255f56a5E366F4F77f5A186d7f55',
    chainId: 1,
    name: 'BandToken',
    symbol: 'BAND',
    decimals: 18
  },
  {
    address: '0x0D8775F648430679A709E98d2b0Cb6250d2887EF',
    chainId: 1,
    name: 'Basic Attention Token',
    symbol: 'BAT',
    decimals: 18
  },
  {
    address: '0x107c4504cd79C5d2696Ea0030a8dD4e92601B82e',
    chainId: 1,
    name: 'Bloom Token',
    symbol: 'BLT',
    decimals: 18
  },
  {
    address: '0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C',
    chainId: 1,
    name: 'Bancor Network Token',
    symbol: 'BNT',
    decimals: 18
  },
  {
    address: '0x0327112423F3A68efdF1fcF402F6c5CB9f7C33fd',
    chainId: 1,
    name: 'PieDAO BTC++',
    symbol: 'BTC++',
    decimals: 18
  },
  {
    address: '0x4F9254C83EB525f9FCf346490bbb3ed28a81C667',
    chainId: 1,
    name: 'CelerToken',
    symbol: 'CELR',
    decimals: 18
  },
  {
    address: '0xF5DCe57282A584D2746FaF1593d3121Fcac444dC',
    chainId: 1,
    name: 'Compound Dai',
    symbol: 'cSAI',
    decimals: 8
  },
  {
    address: '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643',
    chainId: 1,
    name: 'Compound Dai',
    symbol: 'cDAI',
    decimals: 8
  },
  {
    address: '0x39AA39c021dfbaE8faC545936693aC917d5E7563',
    chainId: 1,
    name: 'Compound USD Coin',
    symbol: 'cUSDC',
    decimals: 8
  },
  {
    address: '0xaaAEBE6Fe48E54f431b0C390CfaF0b017d09D42d',
    chainId: 1,
    name: 'Celsius',
    symbol: 'CEL',
    decimals: 4
  },
  {
    address: '0x06AF07097C9Eeb7fD685c692751D5C66dB49c215',
    chainId: 1,
    name: 'Chai',
    symbol: 'CHAI',
    decimals: 18
  },
  {
    address: '0x89d24A6b4CcB1B6fAA2625fE562bDD9a23260359',
    chainId: 1,
    name: 'Dai Stablecoin v1.0 aka SAI',
    symbol: 'SAI',
    decimals: 18
  },
  {
    address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    chainId: 1,
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    decimals: 18
  },
  {
    address: '0x0Cf0Ee63788A0849fE5297F3407f701E122cC023',
    chainId: 1,
    name: 'Streamr DATAcoin',
    symbol: 'DATA',
    decimals: 18
  },
  {
    address: '0xE0B7927c4aF23765Cb51314A0E0521A9645F0E2A',
    chainId: 1,
    name: 'DigixDAO',
    symbol: 'DGD',
    decimals: 9
  },
  {
    address: '0x4f3AfEC4E5a3F2A6a1A411DEF7D7dFe50eE057bF',
    chainId: 1,
    name: 'Digix Gold Token',
    symbol: 'DGX',
    decimals: 9
  },
  {
    address: '0xc719d010B63E5bbF2C0551872CD5316ED26AcD83',
    chainId: 1,
    name: 'Decentralized Insurance Protocol',
    symbol: 'DIP',
    decimals: 18
  },
  {
    address: '0xC0F9bD5Fa5698B6505F643900FFA515Ea5dF54A9',
    chainId: 1,
    name: 'Donut',
    symbol: 'DONUT',
    decimals: 18
  },
  {
    address: '0x86FADb80d8D2cff3C3680819E4da99C10232Ba0F',
    chainId: 1,
    name: 'EURBASE Stablecoin',
    symbol: 'EBASE',
    decimals: 18
  },
  {
    address: '0xF629cBd94d3791C9250152BD8dfBDF380E2a3B9c',
    chainId: 1,
    name: 'Enjin Coin',
    symbol: 'ENJ',
    decimals: 18
  },
  {
    address: '0x06f65b8CfCb13a9FE37d836fE9708dA38Ecb29B2',
    chainId: 1,
    name: 'SAINT FAME Genesis Shirt',
    symbol: 'FAME',
    decimals: 18
  },
  {
    address: '0x4946Fcea7C692606e8908002e55A582af44AC121',
    chainId: 1,
    name: 'FOAM Token',
    symbol: 'FOAM',
    decimals: 18
  },
  {
    address: '0x419D0d8BdD9aF5e606Ae2232ed285Aff190E711b',
    chainId: 1,
    name: 'FunFair',
    symbol: 'FUN',
    decimals: 8
  },
  {
    address: '0x4a57E687b9126435a9B19E4A802113e266AdeBde',
    chainId: 1,
    name: 'Flexacoin',
    symbol: 'FXC',
    decimals: 18
  },
  {
    address: '0x543Ff227F64Aa17eA132Bf9886cAb5DB55DCAddf',
    chainId: 1,
    name: 'DAOstack',
    symbol: 'GEN',
    decimals: 18
  },
  {
    address: '0x6810e776880C02933D47DB1b9fc05908e5386b96',
    chainId: 1,
    name: 'Gnosis Token',
    symbol: 'GNO',
    decimals: 18
  },
  {
    address: '0x12B19D3e2ccc14Da04FAe33e63652ce469b3F2FD',
    chainId: 1,
    name: 'GRID Token',
    symbol: 'GRID',
    decimals: 12
  },
  {
    address: '0x0000000000b3F879cb30FE243b4Dfee438691c04',
    chainId: 1,
    name: 'Gastoken.io',
    symbol: 'GST2',
    decimals: 2
  },
  {
    address: '0xF1290473E210b2108A85237fbCd7b6eb42Cc654F',
    chainId: 1,
    name: 'HedgeTrade',
    symbol: 'HEDG',
    decimals: 18
  },
  {
    address: '0x6c6EE5e31d828De241282B9606C8e98Ea48526E2',
    chainId: 1,
    name: 'HoloToken',
    symbol: 'HOT',
    decimals: 18
  },
  {
    address: '0x493C57C4763932315A328269E1ADaD09653B9081',
    chainId: 1,
    name: 'Fulcrum DAI iToken',
    symbol: 'iDAI',
    decimals: 18
  },
  {
    address: '0x14094949152EDDBFcd073717200DA82fEd8dC960',
    chainId: 1,
    name: 'Fulcrum SAI iToken ',
    symbol: 'iSAI',
    decimals: 18
  },
  {
    address: '0x6fB3e0A217407EFFf7Ca062D46c26E5d60a14d69',
    chainId: 1,
    name: 'IoTeX Network',
    symbol: 'IOTX',
    decimals: 18
  },
  {
    address: '0x4Cd988AfBad37289BAAf53C13e98E2BD46aAEa8c',
    chainId: 1,
    name: 'KEY',
    symbol: 'KEY',
    decimals: 18
  },
  {
    address: '0xdd974D5C2e2928deA5F71b9825b8b646686BD200',
    chainId: 1,
    name: 'Kyber Network Crystal',
    symbol: 'KNC',
    decimals: 18
  },
  {
    address: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
    chainId: 1,
    name: 'ChainLink Token',
    symbol: 'LINK',
    decimals: 18
  },
  {
    address: '0xBBbbCA6A901c926F240b89EacB641d8Aec7AEafD',
    chainId: 1,
    name: 'LoopringCoin V2',
    symbol: 'LRC',
    decimals: 18
  },
  {
    address: '0x80fB784B7eD66730e8b1DBd9820aFD29931aab03',
    chainId: 1,
    name: 'EthLend Token',
    symbol: 'LEND',
    decimals: 18
  },
  {
    address: '0xA4e8C3Ec456107eA67d3075bF9e3DF3A75823DB0',
    chainId: 1,
    name: 'LoomToken',
    symbol: 'LOOM',
    decimals: 18
  },
  {
    address: '0x58b6A8A3302369DAEc383334672404Ee733aB239',
    chainId: 1,
    name: 'Livepeer Token',
    symbol: 'LPT',
    decimals: 18
  },
  {
    address: '0xD29F0b5b3F50b07Fe9a9511F7d86F4f4bAc3f8c4',
    chainId: 1,
    name: 'Liquidity.Network Token',
    symbol: 'LQD',
    decimals: 18
  },
  {
    address: '0x0F5D2fB29fb7d3CFeE444a200298f468908cC942',
    chainId: 1,
    name: 'Decentraland MANA',
    symbol: 'MANA',
    decimals: 18
  },
  {
    address: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
    chainId: 1,
    name: 'Matic Token',
    symbol: 'MATIC',
    decimals: 18
  },
  {
    address: '0x8888889213DD4dA823EbDD1e235b09590633C150',
    chainId: 1,
    name: 'Marblecoin',
    symbol: 'MBC',
    decimals: 18
  },
  {
    address: '0xd15eCDCF5Ea68e3995b2D0527A0aE0a3258302F8',
    chainId: 1,
    name: 'MachiX Token',
    symbol: 'MCX',
    decimals: 18
  },
  {
    address: '0xa3d58c4E56fedCae3a7c43A725aeE9A71F0ece4e',
    chainId: 1,
    name: 'Metronome',
    symbol: 'MET',
    decimals: 18
  },
  {
    address: '0x80f222a749a2e18Eb7f676D371F19ad7EFEEe3b7',
    chainId: 1,
    name: 'Magnolia Token',
    symbol: 'MGN',
    decimals: 18
  },
  {
    address: '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2',
    chainId: 1,
    name: 'Maker',
    symbol: 'MKR',
    decimals: 18
  },
  {
    address: '0xec67005c4E498Ec7f55E092bd1d35cbC47C91892',
    chainId: 1,
    name: 'Melon Token',
    symbol: 'MLN',
    decimals: 18
  },
  {
    address: '0x957c30aB0426e0C93CD8241E2c60392d08c6aC8e',
    chainId: 1,
    name: 'Modum Token',
    symbol: 'MOD',
    decimals: 0
  },
  {
    address: '0xB62132e35a6c13ee1EE0f84dC5d40bad8d815206',
    chainId: 1,
    name: 'Nexo',
    symbol: 'NEXO',
    decimals: 18
  },
  {
    address: '0x1776e1F26f98b1A5dF9cD347953a26dd3Cb46671',
    chainId: 1,
    name: 'Numeraire',
    symbol: 'NMR',
    decimals: 18
  },
  {
    address: '0x985dd3D42De1e256d09e1c10F112bCCB8015AD41',
    chainId: 1,
    name: 'OceanToken',
    symbol: 'OCEAN',
    decimals: 18
  },
  {
    address: '0x4575f41308EC1483f3d399aa9a2826d74Da13Deb',
    chainId: 1,
    name: 'Orchid',
    symbol: 'OXT',
    decimals: 18
  },
  {
    address: '0xD56daC73A4d6766464b38ec6D91eB45Ce7457c44',
    chainId: 1,
    name: 'Panvala pan',
    symbol: 'PAN',
    decimals: 18
  },
  {
    address: '0x8E870D67F660D95d5be530380D0eC0bd388289E1',
    chainId: 1,
    name: 'PAX',
    symbol: 'PAX',
    decimals: 18
  },
  {
    address: '0x45804880De22913dAFE09f4980848ECE6EcbAf78',
    chainId: 1,
    name: 'Paxos Gold',
    symbol: 'PAXG',
    decimals: 18
  },
  {
    address: '0x93ED3FBe21207Ec2E8f2d3c3de6e058Cb73Bc04d',
    chainId: 1,
    name: 'Pinakion',
    symbol: 'PNK',
    decimals: 18
  },
  {
    address: '0x6758B7d441a9739b98552B373703d8d3d14f9e62',
    chainId: 1,
    name: 'POA ERC20 on Foundation',
    symbol: 'POA20',
    decimals: 18
  },
  {
    address: '0x687BfC3E73f6af55F0CccA8450114D107E781a0e',
    chainId: 1,
    name: 'QChi',
    symbol: 'QCH',
    decimals: 18
  },
  {
    address: '0x4a220E6096B25EADb88358cb44068A3248254675',
    chainId: 1,
    name: 'Quant',
    symbol: 'QNT',
    decimals: 18
  },
  {
    address: '0x99ea4dB9EE77ACD40B119BD1dC4E33e1C070b80d',
    chainId: 1,
    name: 'Quantstamp Token',
    symbol: 'QSP',
    decimals: 18
  },
  {
    address: '0xF970b8E36e23F7fC3FD752EeA86f8Be8D83375A6',
    chainId: 1,
    name: 'Ripio Credit Network Token',
    symbol: 'RCN',
    decimals: 18
  },
  {
    address: '0x255Aa6DF07540Cb5d3d297f0D0D4D84cb52bc8e6',
    chainId: 1,
    name: 'Raiden Token',
    symbol: 'RDN',
    decimals: 18
  },
  {
    address: '0x408e41876cCCDC0F92210600ef50372656052a38',
    chainId: 1,
    name: 'Republic Token',
    symbol: 'REN',
    decimals: 18
  },
  {
    address: '0x459086F2376525BdCebA5bDDA135e4E9d3FeF5bf',
    chainId: 1,
    name: 'renBCH',
    symbol: 'renBCH',
    decimals: 8
  },
  {
    address: '0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D',
    chainId: 1,
    name: 'renBTC',
    symbol: 'renBTC',
    decimals: 8
  },
  {
    address: '0x1C5db575E2Ff833E46a2E9864C22F4B22E0B37C2',
    chainId: 1,
    name: 'renZEC',
    symbol: 'renZEC',
    decimals: 8
  },
  {
    address: '0x1985365e9f78359a9B6AD760e32412f4a445E862',
    chainId: 1,
    name: 'Reputation',
    symbol: 'REP',
    decimals: 18
  },
  {
    address: '0x9469D013805bFfB7D3DEBe5E7839237e535ec483',
    chainId: 1,
    name: 'Darwinia Network Native Token',
    symbol: 'RING',
    decimals: 18
  },
  {
    address: '0x607F4C5BB672230e8672085532f7e901544a7375',
    chainId: 1,
    name: 'iEx.ec Network Token',
    symbol: 'RLC',
    decimals: 9
  },
  {
    address: '0xB4EFd85c19999D84251304bDA99E90B92300Bd93',
    chainId: 1,
    name: 'Rocket Pool',
    symbol: 'RPL',
    decimals: 18
  },
  {
    address: '0x4156D3342D5c385a87D264F90653733592000581',
    chainId: 1,
    name: 'Salt',
    symbol: 'SALT',
    decimals: 8
  },
  {
    address: '0x7C5A0CE9267ED19B22F8cae653F198e3E8daf098',
    chainId: 1,
    name: 'SANtiment network token',
    symbol: 'SAN',
    decimals: 18
  },
  {
    address: '0x5e74C9036fb86BD7eCdcb084a0673EFc32eA31cb',
    chainId: 1,
    name: 'Synth sETH',
    symbol: 'sETH',
    decimals: 18
  },
  {
    address: '0x3A9FfF453d50D4Ac52A6890647b823379ba36B9E',
    chainId: 1,
    name: 'Shuffle.Monster V3',
    symbol: 'SHUF',
    decimals: 18
  },
  {
    address: '0x744d70FDBE2Ba4CF95131626614a1763DF805B9E',
    chainId: 1,
    name: 'Status Network Token',
    symbol: 'SNT',
    decimals: 18
  },
  {
    address: '0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F',
    chainId: 1,
    name: 'Synthetix Network Token',
    symbol: 'SNX',
    decimals: 18
  },
  {
    address: '0x23B608675a2B2fB1890d3ABBd85c5775c51691d5',
    chainId: 1,
    name: 'Unisocks Edition 0',
    symbol: 'SOCKS',
    decimals: 18
  },
  {
    address: '0x42d6622deCe394b54999Fbd73D108123806f6a18',
    chainId: 1,
    name: 'SPANK',
    symbol: 'SPANK',
    decimals: 18
  },
  {
    address: '0xB64ef51C888972c908CFacf59B47C1AfBC0Ab8aC',
    chainId: 1,
    name: 'StorjToken',
    symbol: 'STORJ',
    decimals: 8
  },
  {
    address: '0x57Ab1ec28D129707052df4dF418D58a2D46d5f51',
    chainId: 1,
    name: 'Synth sUSD',
    symbol: 'sUSD',
    decimals: 18
  },
  {
    address: '0x261EfCdD24CeA98652B9700800a13DfBca4103fF',
    chainId: 1,
    name: 'Synth sXAU',
    symbol: 'sXAU',
    decimals: 18
  },
  {
    address: '0x8CE9137d39326AD0cD6491fb5CC0CbA0e089b6A9',
    chainId: 1,
    name: 'Swipe',
    symbol: 'SXP',
    decimals: 18
  },
  {
    address: '0x00006100F7090010005F1bd7aE6122c3C2CF0090',
    chainId: 1,
    name: 'TrueAUD',
    symbol: 'TAUD',
    decimals: 18
  },
  {
    address: '0x00000100F2A2bd000715001920eB70D229700085',
    chainId: 1,
    name: 'TrueCAD',
    symbol: 'TCAD',
    decimals: 18
  },
  {
    address: '0x00000000441378008EA67F4284A57932B1c000a5',
    chainId: 1,
    name: 'TrueGBP',
    symbol: 'TGBP',
    decimals: 18
  },
  {
    address: '0x0000852600CEB001E08e00bC008be620d60031F2',
    chainId: 1,
    name: 'TrueHKD',
    symbol: 'THKD',
    decimals: 18
  },
  {
    address: '0xaAAf91D9b90dF800Df4F55c205fd6989c977E73a',
    chainId: 1,
    name: 'Monolith TKN',
    symbol: 'TKN',
    decimals: 8
  },
  {
    address: '0x0Ba45A8b5d5575935B8158a88C631E9F9C95a2e5',
    chainId: 1,
    name: 'Tellor Tributes',
    symbol: 'TRB',
    decimals: 18
  },
  {
    address: '0xCb94be6f13A1182E4A4B6140cb7bf2025d28e41B',
    chainId: 1,
    name: 'Trustcoin',
    symbol: 'TRST',
    decimals: 6
  },
  {
    address: '0x2C537E5624e4af88A7ae4060C022609376C8D0EB',
    chainId: 1,
    name: 'BiLira',
    symbol: 'TRYB',
    decimals: 6
  },
  {
    address: '0x0000000000085d4780B73119b644AE5ecd22b376',
    chainId: 1,
    name: 'TrueUSD',
    symbol: 'TUSD',
    decimals: 18
  },
  {
    address: '0x8400D94A5cb0fa0D041a3788e395285d61c9ee5e',
    chainId: 1,
    name: 'UniBright',
    symbol: 'UBT',
    decimals: 8
  },
  {
    address: '0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828',
    chainId: 1,
    name: 'UMA Voting Token v1',
    symbol: 'UMA',
    decimals: 18
  },
  {
    address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    chainId: 1,
    name: 'USD Coin',
    symbol: 'USDC',
    decimals: 6
  },
  {
    address: '0xA4Bdb11dc0a2bEC88d24A3aa1E6Bb17201112eBe',
    chainId: 1,
    name: 'StableUSD',
    symbol: 'USDS',
    decimals: 6
  },
  {
    address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    chainId: 1,
    name: 'Tether USD',
    symbol: 'USDT',
    decimals: 6
  },
  {
    address: '0xeb269732ab75A6fD61Ea60b06fE994cD32a83549',
    chainId: 1,
    name: 'dForce',
    symbol: 'USDx',
    decimals: 18
  },
  {
    address: '0x8f3470A7388c05eE4e7AF3d01D8C722b0FF52374',
    chainId: 1,
    name: 'Veritaseum',
    symbol: 'VERI',
    decimals: 18
  },
  {
    address: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    chainId: 1,
    name: 'Wrapped BTC',
    symbol: 'WBTC',
    decimals: 8
  },
  {
    address: '0x09fE5f0236F0Ea5D930197DCE254d77B04128075',
    chainId: 1,
    name: 'Wrapped CryptoKitties',
    symbol: 'WCK',
    decimals: 18
  },
  {
    address: '0xB4272071eCAdd69d933AdcD19cA99fe80664fc08',
    chainId: 1,
    name: 'CryptoFranc',
    symbol: 'XCHF',
    decimals: 18
  },
  {
    address: '0x0f7F961648aE6Db43C75663aC7E5414Eb79b5704',
    chainId: 1,
    name: 'XIO Network',
    symbol: 'XIO',
    decimals: 18
  },
  {
    address: '0xE41d2489571d322189246DaFA5ebDe1F4699F498',
    chainId: 1,
    name: '0x Protocol Token',
    symbol: 'ZRX',
    decimals: 18
  },
  {
    address: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    chainId: 3,
    name: 'Wrapped Ether',
    symbol: 'WETH',
    decimals: 18
  },
  {
    address: '0xaD6D458402F60fD3Bd25163575031ACDce07538D',
    chainId: 3,
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    decimals: 18
  },
  {
    address: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    chainId: 4,
    name: 'Wrapped Ether',
    symbol: 'WETH',
    decimals: 18
  },
  {
    address: '0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735',
    chainId: 4,
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    decimals: 18
  },
  {
    address: '0xF9bA5210F91D0474bd1e1DcDAeC4C58E359AaD85',
    chainId: 4,
    name: 'Maker',
    symbol: 'MKR',
    decimals: 18
  },
  {
    address: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    chainId: 5,
    name: 'Wrapped Ether',
    symbol: 'WETH',
    decimals: 18
  },
  {
    address: '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
    chainId: 42,
    name: 'Wrapped Ether',
    symbol: 'WETH',
    decimals: 18
  },
  {
    address: '0x4F96Fe3b7A6Cf9725f59d353F723c1bDb64CA6Aa',
    chainId: 42,
    name: 'Dai Stablecoin',
    symbol: 'DAI',
    decimals: 18
  },
  {
    address: '0xAaF64BFCC32d0F15873a02163e7E500671a4ffcD',
    chainId: 42,
    name: 'Maker',
    symbol: 'MKR',
    decimals: 18
  }
]
