import React from 'react'

const Mountains = () => {
  return (
    <>
      <div className="mountain">
        <div className="mountain-top">
          <div className="mountain-cap-1"></div>
          <div className="mountain-cap-2"></div>
          <div className="mountain-cap-3"></div>
        </div>
      </div>
      <div className="mountain-two">
        <div className="mountain-top">
          <div className="mountain-cap-1"></div>
          <div className="mountain-cap-2"></div>
          <div className="mountain-cap-3"></div>
        </div>
      </div>
      <div className="mountain-three">
        <div className="mountain-top">
          <div className="mountain-cap-1"></div>
          <div className="mountain-cap-2"></div>
          <div className="mountain-cap-3"></div>
        </div>
      </div>
    </>
  )
}

export default Mountains
